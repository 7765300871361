<template>
    <div>
        <!-- jeepay中，付款的点击事件 由 payment 修改为 pay  -->
        <!-- jeepay中，付款页面是唯一的，颜色不在需要v-bind，去掉即可 -->
        <!-- <div class="bnt-pay" v-if="!isAllowModifyAmount"> -->
        <div class="bnt-pay">
            <div class="bnt-pay-text" style="background-color:#07c160" @click="openApplet">
                打开小程序
            </div>
        </div>
    </div>
</template>

<script>

import { getPayOrderInfo } from '@/api/qwxpay/index'
import config from "@/config";

export default {

    data: function () {
        return {
            concealSate: false,
            mchName: '',
            merchantName: 'aiopay', // 付款的商户默认
            resData: {},
            payOrderInfo: {}, //订单信息,
            payOrderId: "",
        }
    },

    mounted() {
        this.payOrderId = this.$route.query.payOrderId
        setTimeout(() => {
            this.openApplet();
        }, 1000);

    },

    methods: {
        openApplet() {
            const _this = this
            // var res = {
            //     amount: "1.80",
            //     applet_name: "小程序名称",
            //     mch_no: "M1664724998",
            //     openlink: "weixin://dl/business/?t=KvJLzPHb9oh",
            //     payOrderId: "P1742606491640143874",
            //     state: 1,
            //     sub_appid: "wxee10cba9043bed7g",
            //     sub_mch_id: "1664245374",
            //     way_code: "QWXPAY_WX_LITE"
            // }
            // return window.location.href = res.openlink;

            getPayOrderInfo(this.payOrderId).then(res => {
                if (res.openlink !== undefined && res.openlink) {
                    window.location.href = res.openlink;
                } else {
                    _this.$router.push({
                        name: config.errorPageRouteName,
                        params: {
                            errInfo: "Server Error[缺少必要参数]"
                        }
                    })
                }

            })
        },

    }


}
</script>
<style lang="css" scoped>
@import './pay.css';
</style>
